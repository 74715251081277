/* purgecss start ignore */

.authRoot{
  display:flex;
  align-items:center;
  background-size:cover
}

@media (min-width: 640px){
  .authRoot{
    background-image:url(https://i.assetzen.net/i/aB37uXzZo7QA/w:1900/q:70.webp)
  }
}

.authRoot>div:nth-child(1){
  position:absolute;
  top:0px;
  left:0px;
  z-index:0;
  height:100%;
  width:100%
}

.authRoot>div:nth-child(2){
  z-index:10;
  margin-left:auto;
  margin-right:auto;
  margin-top:2.5rem;
  margin-bottom:2.5rem;
  width:100%;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left:1.25rem;
  padding-right:1.25rem;
  padding-top:1.75rem;
  padding-bottom:1.75rem;
  --tw-shadow:0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

@media (min-width: 640px){
  .authRoot>div:nth-child(2){
    width:83.333333%;
    padding-left:3rem;
    padding-right:3rem
  }
}

@media (min-width: 768px){
  .authRoot>div:nth-child(2){
    width:66.666667%
  }
}

@media (min-width: 1024px){
  .authRoot>div:nth-child(2){
    width:41.666667%
  }
}

.authRoot>div:nth-child(2){
  margin-top:120px
}

.authRoot>div:nth-child(2) h1{
  text-align:center;
  font-size:1.5rem;
  line-height:2rem;
  font-weight:700;
  text-transform:capitalize;
  --tw-text-opacity:1;
  color:rgba(35, 76, 121, var(--tw-text-opacity))
}

.authDescription{
  margin-top:0.75rem;
  margin-bottom:0.75rem;
  text-align:center;
  font-family:"Source Sans Pro", sans-serif;
  font-size:1rem;
  line-height:1.5rem;
  --tw-text-opacity:1;
  color:rgba(87, 87, 87, var(--tw-text-opacity))
}

.divisionBlock{
  margin-top:1rem;
  margin-bottom:1rem;
  display:flex
}

.divisionBlock>div:nth-child(2){
  flex-grow:1;
  text-align:center;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(87, 87, 87, var(--tw-text-opacity))
}

.divisionBlock>div>hr{
  margin-top:0.75rem;
  width:6rem;
  border-top-width:2px;
  --tw-border-opacity:1;
  border-color:rgba(87, 87, 87, var(--tw-border-opacity));
  --tw-border-opacity:0.5
}

@media (min-width: 640px){
  .divisionBlock>div>hr{
    width:9rem
  }
}

.forgotPasswordLabel{
  margin-top:0.75rem;
  margin-bottom:0.75rem;
  cursor:pointer;
  text-align:right;
  font-family:Lato, sans-serif;
  font-size:0.875rem;
  line-height:1.25rem;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(87, 87, 87, var(--tw-text-opacity));
  text-decoration:underline
}

.submitButton{
  width:100%;
  --tw-bg-opacity:1;
  background-color:rgba(35, 76, 121, var(--tw-bg-opacity));
  padding-top:0.75rem;
  padding-bottom:0.75rem;
  text-align:center;
  font-weight:700;
  text-transform:uppercase;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.redirectionCTA{
  margin-top:0.75rem;
  margin-bottom:0.75rem;
  text-align:center;
  font-family:Lato, sans-serif;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(87, 87, 87, var(--tw-text-opacity))
}

/* purgecss end ignore */