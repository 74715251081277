.authRoot {
    @apply flex items-center sm:bg-auth bg-cover;
    > div:nth-child(1) {
        @apply w-full h-full absolute top-0 left-0 z-0;
    }
    > div:nth-child(2) {
        @apply w-full px-5 sm:w-10/12 md:w-8/12 lg:w-5/12 my-10 py-7 sm:px-12 mx-auto bg-white shadow-md z-10;
        margin-top: 120px;
        h1 {
            @apply text-2xl text-vacayou-primaryDark text-center capitalize font-bold;
        }
    }
}

.authDescription {
    @apply font-source text-base text-vacayou-defaultTextGray my-3 text-center;
}

.divisionBlock {
    @apply flex my-4;
    > div:nth-child(2) {
        @apply flex-grow text-center text-vacayou-defaultTextGray font-bold;
    }
    > div {
        > hr {
            @apply sm:w-36 w-24 mt-3 border-t-2 border-opacity-50 border-vacayou-defaultTextGray;
        }
    }
}

.forgotPasswordLabel {
    @apply my-3 underline font-bold font-lato text-sm text-right text-vacayou-defaultTextGray cursor-pointer;
}

.submitButton {
    @apply w-full py-3 font-bold bg-vacayou-primaryDark text-white uppercase text-center;
}

.redirectionCTA {
    @apply my-3 font-bold font-lato text-center text-vacayou-defaultTextGray;
}
